/*
 * @Description: 菜单配置
 */
import React from 'react';
import {
  ApartmentOutlined,
  SplitCellsOutlined,
  ApiOutlined,
  BarChartOutlined,
  ConsoleSqlOutlined,
  DatabaseOutlined,
  EditOutlined,
  ExpandAltOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  GlobalOutlined,
  HeatMapOutlined,
  LineChartOutlined,
  SwapOutlined,
  HomeOutlined,
  KeyOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  DotChartOutlined,
  RiseOutlined,
  SafetyCertificateOutlined,
  ToolOutlined,
  SettingOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  InsuranceOutlined,
  TeamOutlined,
  TranslationOutlined,
  FundProjectionScreenOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const IconList = {
  home_shouye: <HomeOutlined />,
  mode_shujuguifan: <SplitCellsOutlined />,
  mode_zonglan: <BarChartOutlined />,
  mode_xinxijiagou: <ApartmentOutlined />,
  mode_shujudiaoyan: <EditOutlined />,
  mode_biaozhunsheji: <OrderedListOutlined />,
  mode_moxingsheji: <HeatMapOutlined />,
  mode_zhibiaosheji: <RiseOutlined />,
  mode_peizhizhongxin: <SnippetsOutlined />,
  integration_shujujicheng: <ApiOutlined />,
  integration_piliangqianyi: <SwapOutlined />,
  integration_shishishuju: <ExpandAltOutlined />,
  dev_shujukaifa: <ConsoleSqlOutlined />,
  dev_jiaobenkaifa: <FileSyncOutlined />,
  dev_zuoyekaifa: <FileDoneOutlined />,
  dev_yunweidiaodu: <SolutionOutlined />,
  dev_peizhiguanli: <ToolOutlined />,
  quality_shujuzhiliang: <RadarChartOutlined />,
  quality_yewuzhibiaojiankong: <LineChartOutlined />,
  quality_shujuzhiliangjiankong: <DotChartOutlined />,
  assets_shujuzichan: <DatabaseOutlined />,
  assets_shujuditu: <GlobalOutlined />,
  assets_shujuquanxian: <KeyOutlined />,
  assets_yuanshujucaiji: <ProfileOutlined />,
  safe_shujuanquan: <SafetyCertificateOutlined />,
  safe_zonglan: <PieChartOutlined />,
  safe_tuiminshujushibie: <TranslationOutlined />,
  safe_yinsibaohuguanli: <InsuranceOutlined />,
  server_shujufuwu: <FundProjectionScreenOutlined />,
  account_zhanghu: <TeamOutlined />,
  account_gerenzhongxin: <UserOutlined />,
  account_gerenshezhi: <SettingOutlined />,
};

export const asideMenuConfig = [
  {
    title: '首页',
    path: '/home',
    icon: 'home_shouye',
  },
  {
    title: '仪表盘',
    path: '/dashboard',
    icon: 'home_shouye',
    children: [
      {
        title: '监控',
        path: '/dashboard/monitor',
        icon: 'home_shouye',
      },
      {
        title: '表格',
        path: '/dashboard/table',
        icon: 'home_shouye',
        children: [
          {
            title: '基本',
            path: '/dashboard/basic',
            icon: 'home_shouye',
          },
          {
            title: '一般',
            path: '/dashboard/general',
            icon: 'home_shouye',
          },
        ],
      },
    ],
  },
  {
    title: '图表',
    path: '/chart',
    icon: 'home_shouye',
    children: [
      {
        title: '基本',
        path: '/chart/basic',
        icon: 'home_shouye',
      },
      {
        title: '一般',
        path: '/chart/general',
        icon: 'home_shouye',
        children: [
          {
            title: '成功',
            path: '/chart/success',
            icon: 'home_shouye',
          },
          {
            title: '失败',
            path: '/chart/fail',
            icon: 'home_shouye',
          },
        ],
      },
    ],
  },
  {
    title: '列表页',
    path: '/list',
    icon: 'home_shouye',
    children: [
      {
        title: '基本',
        path: '/list/basic',
        icon: 'home_shouye',
      },
      {
        title: '一般',
        path: '/list/general',
        icon: 'home_shouye',
      },
    ],
  },
  {
    title: '配置',
    path: '/profile',
    icon: 'home_shouye',
    children: [
      {
        title: '基本',
        path: '/profile/basic',
        icon: 'home_shouye',
      },
      {
        title: '一般',
        path: '/profile/general',
        icon: 'home_shouye',
      },
    ],
  },
  {
    title: '结果',
    path: '/result',
    icon: 'home_shouye',
    children: [
      {
        title: '成功',
        path: '/result/success',
        icon: 'home_shouye',
      },
      {
        title: '失败',
        path: '/result/fail',
        icon: 'home_shouye',
      },
    ],
  },
  {
    title: '账户',
    path: '/account',
    icon: 'account_zhanghu',
    children: [
      {
        title: '个人中心',
        path: '/account/center',
        icon: 'account_gerenzhongxin',
      },
      {
        title: '个人设置',
        path: '/account/setting',
        icon: 'account_gerenshezhi',
      },
    ],
  },
];

function findKeys(list, pathname, middleList, openKeys, selectedKeys) {
  list.forEach((element) => {
    const { path, children = [] } = element;

    if (path === pathname || `${path}/` === pathname) {
      openKeys.push(...middleList);
      selectedKeys.push(path);
    } else {
      if (children.length > 0) {
        middleList.push(path);
        findKeys(children, pathname, middleList, openKeys, selectedKeys);
      }
    }
  });
}

// 获取默认打开的menu，以及默认选中的menu
export function getKeys(pathname = '') {
  const openKeys = [];
  const selectedKeys = [];

  asideMenuConfig.forEach((element) => {
    const { path, children = [] } = element;

    if (children.length === 0) {
      if (path === pathname || `${path}/` === pathname) {
        selectedKeys.push(path);
      }
    } else {
      const middleList = [path];
      findKeys(children, pathname, middleList, openKeys, selectedKeys);
    }
  });

  return { openKeys, selectedKeys };
}
