/*
 * @Description: 基本布局
 */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Header from './Header';
import SiderMenu from './SiderMenu';
import styles from './index.module.less';

export default function BasicLayout() {
  const [collapsed, setCollapsed] = useState(false);

  // 展开/关闭左侧导航
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.basicLayout}>
      <div className={styles.headerWrap}>
        <Header />
      </div>

      <div className={styles.leftWrap}>
        <div className={styles.siderDiv}>
          <SiderMenu isCollapsed={collapsed} />
        </div>
        <div className={styles.controlDiv} onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>

      <div className={styles.mainWrap}>
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
