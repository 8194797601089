/*
 * @Description: 基本布局-头部
 */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Space } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { removeToken } from '@/common/auth.js';
import styles from './index.module.less';

export default function Header() {
  const navigate = useNavigate();

  // 点击退出登陆
  const clickLogoutOut = () => {
    removeToken();
    navigate('/user/login');
  };

  // 渲染用户下拉菜单
  const renderUserMenu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} key="1">
        <Link to="/account/center">个人中心</Link>
      </Menu.Item>
      <Menu.Item icon={<SettingOutlined />} key="2">
        <Link to="/account/setting">个人设置</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />} onClick={clickLogoutOut} key="3">
        退出登陆
      </Menu.Item>
    </Menu>
  );

  const avatar = '';

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoWrap}>
        <span className={styles.logo}>LOGO</span>
        <span className={styles.title}>这里书写项目名称</span>
      </div>

      <div className={styles.actionWrap}>
        <Dropdown arrow overlay={renderUserMenu} placement="bottomCenter">
          <Space className={styles.userSpaceWrap}>
            {avatar ? <img src={avatar} alt="avatar" /> : <UserOutlined />}
            <span>user</span>
          </Space>
        </Dropdown>
      </div>
    </div>
  );
}
