/*
 * @Description: 用户 token 设置(存储为Cookie)
 */
import Cookies from 'js-cookie';

// Cookie名称
const tokenName = 'AUTHORIZATION_TOKEN';

// 过期时间:1天
let tokenExpiration = 24 * 60 * 60 * 1000;

// 设置免登时间
export function setTokenExpiration(expires) {
  tokenExpiration = expires;
}

// 存储
export function setToken(token) {
  Cookies.set(tokenName, token, {
    expires: new Date(new Date().getTime() + tokenExpiration),
  });
}

// 获取
export function getToken() {
  return Cookies.get(tokenName);
}

// 删除
export function removeToken() {
  Cookies.remove(tokenName);
}
