/*
 * @Description: 页面路由不存在
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Empty } from 'antd';
import styles from './index.module.less';

export default function NotFound() {
  return (
    <div className={styles.wrap}>
      <Empty description={<span>抱歉，您访问的页面不存在</span>}>
        <div>
          请返回<Link to="/"> 首页 </Link>继续浏览
        </div>
      </Empty>
    </div>
  );
}
