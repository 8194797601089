/*
 * @Description: 路由配置
 */
import React from 'react';
import { Navigate } from 'react-router-dom';

// 每个Layout组件，都进行登录态校验，确定重定向/user/login或/home
import UserLayout from '@/layouts/UserLayout';
import BasicLayout from '@/layouts/BasicLayout';

import NotFound from '@/components/NotFound';

const UserLogin = React.lazy(() => import('@/pages/UserLogin'));
const UserRegister = React.lazy(() => import('@/pages/UserRegister'));
const ForgotPassword = React.lazy(() => import('@/pages/ForgotPassword'));

const Home = React.lazy(() => import('@/pages/Home'));
const AccountCenter = React.lazy(() => import('@/pages/AccountCenter'));
const AccountSetting = React.lazy(() => import('@/pages/AccountSetting'));

const routerConfig = [
  {
    path: '/',
    element: <BasicLayout />,
    children: [
      { path: '', element: <Home /> },
      { path: '1', element: <AccountCenter /> },
      { path: '2', element: <AccountSetting /> },
    ],
  },
  {
    path: '/user',
    element: <UserLayout />,
    children: [
      { path: '', element: <Navigate to="/user/login" /> },
      { path: 'login', element: <UserLogin /> },
      { path: 'register', element: <UserRegister /> },
      { path: 'forgotPassword', element: <ForgotPassword /> },
    ],
  },
  { path: '*', element: <NotFound /> },
];

export default routerConfig;
