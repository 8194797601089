import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import PageLoading from '@/components/PageLoading';

// antd、moment语言包
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';

// 性能
import reportWebVitals from './reportWebVitals';

// 路由
import routerConfig from './config/routerConfig';

import 'antd/dist/antd.less';

function App() {
  const content = useRoutes(routerConfig);
  return <ConfigProvider locale={zhCN}>{content}</ConfigProvider>;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <React.Suspense fallback={<PageLoading />}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
