/*
 * @Description: 用户布局（登陆、注册、忘记密码）
 */
import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './index.module.less';

export default function UserLayout() {
  return (
    <div className={styles.userLayout}>
      <div className={styles.header}>
        <span className={styles.title}>LOGO</span>
        <p className={styles.desc}>这里书写项目名称</p>
      </div>

      <div className={styles.contentWrap}>
        <Outlet />
      </div>
    </div>
  );
}
