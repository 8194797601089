/*
 * @Description: 页面加载loading
 */
import React from 'react';
import { Spin } from 'antd';
import styles from './index.module.less';

export default function PageLoading() {
  return (
    <div className={styles.wrap}>
      <Spin />
    </div>
  );
}
