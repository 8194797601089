/*
 * @Description: 基本布局-左侧菜单
 */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { asideMenuConfig, getKeys, IconList } from '@/config/menuConfig.js';

export default function SiderMenu(props) {
  const { isCollapsed } = props;

  const { pathname } = useLocation();
  const allKeys = getKeys(pathname);

  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const ret = getKeys(pathname);
    if (isCollapsed) {
      return;
    }
    setOpenKeys(ret.openKeys);
  }, [pathname, isCollapsed]);

  // 手动展开/关闭
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  // 渲染菜单
  const renderMenu = (configList) => {
    return configList.map((element) => {
      const { title, path, icon, children = [] } = element;

      if (children.length === 0) {
        return (
          <Menu.Item icon={icon && IconList[icon]} key={path}>
            <Link to={path}>{title}</Link>
          </Menu.Item>
        );
      }

      return (
        <Menu.SubMenu title={title} icon={icon && IconList[icon]} key={path}>
          {renderMenu(children)}
        </Menu.SubMenu>
      );
    });
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={allKeys.selectedKeys}
      onOpenChange={onOpenChange}
      style={{
        width: isCollapsed ? '60px' : '256px',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      }}
      inlineCollapsed={isCollapsed}
    >
      {renderMenu(asideMenuConfig)}
    </Menu>
  );
}
